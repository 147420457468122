import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

function CNBNotificationScreen() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [error, setError] = useState("");
  const showToast = () => {
    toast.success('Notification Successfully sent to all devices', {
      position: 'top-right',
      autoClose: 2000, 
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleLogin = async() => {
    if(!title)
    {
      setError('Please enter the title of Notification')
      return
    }
    if(!body)
    {
      setError('Please enter the body of Notification')
      return
    }
    try {
      const title1 = 'blupace24';
        const body1 = 'Loginbody@';
        const base64Credentials = btoa(`${title1}:${body1}`);
        const headers = {
          'Authorization': `Basic ${base64Credentials}`,
          'Content-Type': 'application/json',
          
        };
      const response = await axios.post("http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/sendNotification/cnb",
        {
          title: title,
          body: body,
        },
        {
          headers:headers
        }
      );
      showToast();
      setError('');
      setBody('');
      setTitle('');
      console.log('Login Successfull****',response.data)
    } 
    catch (error) {
      console.error("Error fetching data:", error);
      if(error.message == "Request failed with status code 500"){
        setError("Wrong body or title!")
      }
    else{
      setError("Something went wrong! Please try  again")
    }
    }
  };

  return (
    <div className='row'>
         <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
      <div className='col-md-6' style={{alignContent:'center', justifyItems:'center',paddingBottom:0, paddingTop:0, padding:60, }}>
      <Link to="/notifications" >
  <div style={{display: 'flex', marginBottom:20}}>
  <button style={{ backgroundColor:'white'}}>
      &#8592; Back
    </button>
    </div>
    </Link>
      <div style={{lineHeight:0.5, display:'flex', flexDirection:'column', }}>

      <h2 style={{textAlign:'left', color:'#244999'}}>Cakes & Bakes App Notifications </h2>
      <p style={{marginTop:'10px', color:'red', fontSize:16,textAlign:'left', marginBottom:20}}>{error}</p>
      
        <p style={{textAlign:'left', color:'#7E7E7E'}}>Title</p>
        <input style={{height:30, color:'#000000'}} type="text" value={title} onChange={(e) => setTitle(e.target.value)}  />
      
        <p style={{textAlign:'left', marginTop:30,color:'#7E7E7E'}}>Body</p>
        <textarea id="multiline-input" value={body} onChange={(e) => setBody(e.target.value)} style={{height:50}} />
        <br />
        <button type="button" onClick={handleLogin} style={{backgroundColor:'#048E5B', marginTop:20, width:200, borderWidth:0, borderRadius:5, height:40,color:'white'}}>Send Notification</button>
        </div>
        </div>
    </div>
  );
}

export default CNBNotificationScreen;
