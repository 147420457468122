import './App.css';
import CNBNotificationScreen from './components/CNBNotificationScreen';
import { BrowserRouter as Router, Routes, Route,Link } from "react-router-dom";
import LoginPage from './components/LoginPage';
import LayoutScreen from './components/LayoutScreen';
import WalaNotificationScreen from './components/WalaNotificationScreen';

function App() {
  return (
    <Router>
    <div className="App">
      
      <Routes>
      <Route
          exact
          path="/"
          element={<LoginPage/>}
        ></Route>
         <Route
          exact
          path="/notifications"
          element={<LayoutScreen/>}
        ></Route>
         <Route
          exact
          path="/notifications/cakesandbakes"
          element={<CNBNotificationScreen/>}
        ></Route>
         <Route
          exact
          path="/notifications/hyderabadwala"
          element={<WalaNotificationScreen/>}
        ></Route>
      </Routes>
    </div>
  </Router>
  );
}

export default App;
