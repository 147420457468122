import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure this is imported if using Bootstrap
import dashboard3 from "../assets/dashboard3.png";
import dashboard4 from "../assets/dashboard4.png";

const LayoutScreen = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/'); 
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="App-header" style={{ backgroundColor: '#244999' }}>
      <div className="row" style={{ margin: 10 }}>
        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/notifications/hyderabadwala">
            <div style={{
              borderRadius: 5,
              height: '200px',
              width: 200,
              color: 'white',
              border: '2px solid white',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10
            }}>
              <img src={dashboard3} alt="Description of the image" style={{ width: 180, height: 180 }} />
            </div>
          </Link>
        </div>
        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/notifications/cakesandbakes">
            <div style={{
              borderRadius: 5,
              height: '200px',
              width: 200,
              color: 'white',
              border: '2px solid white',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10
            }}>
              <img src={dashboard4} alt="Description of the image" style={{ width: 100, height: 80 }} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LayoutScreen;
